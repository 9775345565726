var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner-page-container"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"heading-bar"},[_c('h1',[_vm._v(_vm._s(_vm.$t('nav.my_bookings')))])]),_c('vue-good-table',{attrs:{"mode":"remote","styleClass":"vgt-custom vgt-table","columns":_vm.columns,"rows":_vm.bookings,"isLoading":_vm.is_loading_bookings,"search-options":{
                enabled: false,
            },"pagination-options":{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                perPage: 15,
                perPageDropdownEnabled: false,
                rowsPerPageLabel: _vm.$t('x_per_page', {x: _vm.$t('bookings.bookings')}),
            },"sort-options":{
              enabled: true,
              multipleColumns: true,
            },"totalRows":_vm.totalRecords},on:{"update:isLoading":function($event){_vm.is_loading_bookings=$event},"update:is-loading":function($event){_vm.is_loading_bookings=$event},"on-page-change":_vm.onPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'attributes.from')?_c('div',[_vm._v(" "+_vm._s(_vm.$moment(props.row.attributes.from).format("DD/MM/YYYY - hh:mm A"))+" ")]):(props.column.field === 'attributes.to')?_c('div',[_vm._v(" "+_vm._s(_vm.$moment(props.row.attributes.to).format("DD/MM/YYYY - hh:mm A"))+" ")]):(props.column.field === 'after')?_c('div',{staticClass:"td-after"},[(props.row.attributes.status === 'pending')?_c('button',{staticClass:"icon",on:{"click":function($event){return _vm.toggleStart(props.row)}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'play']}})],1):(props.row.attributes.status === 'in_progress')?_c('button',{staticClass:"icon",on:{"click":function($event){return _vm.toggleStop(props.row)}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'stop']}})],1):_vm._e(),(props.row.attributes.status !== 'completed')?_c('button',{staticClass:"delete-icon",attrs:{"disabled":props.row.attributes.status === 'in_progress'},on:{"click":function($event){return _vm.toggleDelete(props.row)}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'minus-circle']}})],1):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }